import React from 'react';

export default function AboutUs() {
  return (
    <div className="container">

    <header className="jumbotron">
      <h3>About Us</h3>
    </header>

    </div>
  )
}
