import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import React from "react";
import PrivateRouter from "./PrivateRouter";
import DashBoardLayout from "../components/layouts/dashboard";
import Login from "../components/Auth/login";
import Home from "../components/Dashboard/Home";
import Users from "../components/Users/users";
import Transactions from "../components/transactions/Transactions";
import TransactionDetails from "../components/transactions/TransactionDetails";
import Withdrawals from "../components/withdrawals/Withdrawals";
import Support from "../components/Support";
import StaticContent from "../components/StaticContent/StaticContent";
import UserView from "../components/Users/userView";
import AuthLayout from "../components/layouts/auth";
import EditUser from "../components/Users/userEdit";
import Category from "../components/Category/Category";
import Properties from "../components/properties/Properties";
import AboutUs from "../components/AboutUs";


const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/*"} element={<Navigate to="/dashboard" />} />
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route element={<DashBoardLayout />}>
            <Route path="/dashboard" element={<Home />} />
            <Route path="/users" element={<Users />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/categories" element={<Category />} />
            <Route path="/properties" element={<Properties />} />
            <Route
              path="/transaction-details/:id"
              element={<TransactionDetails />}
            />
            <Route path="/withdrawals" element={<Withdrawals />} />
            <Route path="/support" element={<Support />} />
            <Route path="/about-us" element={<AboutUs />} />

            {["/aboutus", "/privacy", "/terms","/faq"]?.map((path) => (
              <Route key={path} path={path} element={<StaticContent />} />
            ))}
            <Route path="/users/:id/view" element={<UserView />} />
            <Route path="/users/:id/edit" element={<EditUser />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
