import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useGetUserDetailQuery, useEditUserMutation } from "../../services/UserApi"
import { toast } from "react-toastify";
//import LoadingAction from "../../../../components/Loader";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const buttonWidth = {
    width: "100%",
};

const UserView = (props) => {
    const { showView, handleClose, userID } = props;
    const [editUser] = useEditUserMutation();
    const { data, isLoading, isError } = useGetUserDetailQuery(userID);
    const [categoryName, setCategoryName] = useState("");
    const [userDatail, setUserDetail] = useState('')


    useEffect(() => {
        setCategoryName(data?.data?.user?.name)
        setUserDetail(data?.data?.user)
    }, [data, userID])

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value);
    };



    const handleSubmit = (e) => {
        handleClose();
    };

    return (
        <Modal show={showView} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">View User</Modal.Title>
                </div>
            </Modal.Header>
            {/* {showLoader && <LoadingAction />} */}
            <Modal.Body className="p-4">
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3 justify-content-center nav-justified mb-4 modal-Tabs"
                >
                    <Tab eventKey="home" title="User Details" className="">


                        <div className="mb-4">
                            <label className="form-label mb-0">User Profile</label>
                            <div className="mb-4">
                                <img src="https://picsum.photos/100/100" className="img-thumbnail" alt="" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">User Name</label>
                                    <p className="h5 fw-bolder">{userDatail?.name}</p>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">User Email</label>
                                    <p className="h5 fw-bolder">{userDatail?.email}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-4">
                                <label className="form-label mb-0">User Phone Number</label>
                                <p className="h5 fw-bolder">{userDatail?.phoneNumber}</p>
                            </div>
                        </div>



                    </Tab>
                    <Tab eventKey="profile" title="Business Profile">
                        <div className="mb-4">
                            <label className="form-label mb-0">User Profile</label>
                            <div className="mb-4">
                                <img src="https://picsum.photos/100/100" className="img-thumbnail" alt="" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">User Name</label>
                                    <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.name}</p>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Address</label>
                                    <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.address}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-4">
                                <label className="form-label mb-0">Phone Number</label>
                                <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.phone}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <label className="form-label mb-0">State</label>
                                <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.state}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-4">
                                <label className="form-label mb-0">Descriptions</label>
                                <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.description}</p>
                            </div>
                        </div>
                        </div>

                      

                    </Tab>
                </Tabs>
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-primary py-3 fw-bolder"
                            style={buttonWidth}
                        >
                            close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};



export default UserView