import React, { useEffect, useState } from "react";
import Editor from "../elements/Editor/Editor";
import { useDispatch, useSelector } from "react-redux";
import { getContent, saveContent } from "../redux/actions/staticContentActions";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const StaticContent = () => {
  const pathObj = {
    "/aboutus": { label: "About Us", value: 0 },
    "/privacy": { label: "Privacy Policy", value: 1 },
    "/terms": { label: "Terms & Conditions", value: 2 },
    "/faq": { label: "FAQ", value: 3 },
  };
  const { pathname } = useLocation();

  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const content = useSelector((state) => state.staticContent.content);
  const content_type = useSelector((state) => state.staticContent.content_type);

  useEffect(() => {
    dispatch(getContent(pathObj[pathname]?.value));
  }, [pathname]);

  const handleSubmit = () => {
    dispatch(
      saveContent({ content_type: pathObj[pathname]?.value, content: value })
    );
  };

  useEffect(() => {
    if (content_type === pathObj[pathname]?.value) {
      setValue(content);
    } else {
      setValue("");
    }
  }, [content_type]);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>{pathObj[pathname]?.label}</h3>
      </header>

      <Helmet>
        <title>{pathObj[pathname]?.label}</title>
      </Helmet>

      <div class="card">
        <div class="card-body">
          <Editor value={value} setValue={setValue} />
          <div>
            <button onClick={handleSubmit} className="btn submit-btn">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticContent;
